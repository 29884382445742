<script setup lang="ts">
import { useProvider } from '/~/composables/provider'
import BaseAsidePageV1 from './base-aside-page.v1.vue'
import BaseAsidePageV2 from './base-aside-page.v2.vue'

const { isBillPaymentsTemplate } = useProvider()
</script>

<template>
  <component
    :is="isBillPaymentsTemplate ? BaseAsidePageV2 : BaseAsidePageV1"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) in $slots" #[slot]>
      <slot :name="slot"></slot>
    </template>
  </component>
</template>
